
html {
  font-size: inherit !important;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  /* font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */

  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
  line-height: 1.42857;
  color: #333333;
  background-color: #fff; 
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.social-btn {
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 16px;
}


/* Mohit code */
.wraping-categories .list-cx-h ul{
  margin-top: 10px;
  margin-left:10px;
}
.wraping-categories .list-cx-h ul .gx-dv a{
  width: 100%;
    display: block;
    cursor: pointer;
}
span.gx-dv.gx-dv0.main_Catetory{
  color: #ebad1e;
    border-bottom: 1px solid #cccccc36;
    font-size: 16px;
    width: 100%;
    display: block;
}
/* Mohit code */


.social-btn img {
  height: 32px;
  float: left;
  margin-top: 0px;
  margin-right: 25px;
}
.c_mts{
  position: relative;
  top: 4px;
}

.social-btn.google {
  margin-top: 0px;
}

.social-btn.facebook img {
  height: 24px;
  margin-left: 3px;
}

.signup-link {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}

.login-title {
  font-size: 1.5em;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 30px;
  color: rgba(0, 0, 0, 0.65);
}

/* ---------custom-cs--by-anjani for mui */
/* .input-label label::placeholder{
  font-size: 16px !important;
}
.input-label label{
  font-size: 16px !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  
  height: 2.4375em !important;
 
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  height: 2.4375em !important;
} */
.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.app__container{
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}
.app__container1{
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

/* / --------feedback-btns------------ / */
.cover_us{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.box-btn_us{
  width: 20%;
  margin:15px 25px 15px 0px;
}

.box-btn_us .btn {
  width: 100%;
  padding: 15px;
  border-radius: 30px;
}
.box-btn_us .btn:hover{
  background-color: #0083ca;
  color: #fff;
}
.btn:focus{
  border: none !important;
  outline: none !important;
}
.submi_btn .btn {
  width: 20%;
  text-align: center;
  margin:20px auto;
}
.submi_btn{
  text-align: end;
}

@media screen and (max-width:767px) {
  .box-btn_us{
    width: 100%;
    margin:15px;
  }
}


/* -------------Imgage-report------------ */
.image-wraping{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.image-wrap{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.image-preview{
  position: relative !important;
}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  position: absolute !important;
  right: -2px !important;
  top:-9px !important;
}
.css-1yxmbwk {
  top: -10px;
  right: -3px;
}
.image-container {
  width: 22%;
  margin: 10px;
}
.image-preview img {
  /* height: 90px;
  width: 140px; */
  background-size: cover;

}
.upload-btn {
  margin-left: 15px;
}
.upload__btn{
  background: #2a89ba !important;
    color: #fff !important;
    border: 1px solid #2a89ba !important;
}
.upload__btn:hover{
  background: #ebad1e !important;
  border: 1px solid #ebad1e!important;
}
.txt-space{
  display: flex;
}
.spaces{
  margin-left: 5px;
}
.react-tel-input .form-control{
  height: 45px !important;
}


.main_rts{
  display: flex;
  align-items: center;
}
.ytds{
  margin-right: 10px;
}



.topdr-img {
  padding: 0px;
}
.topdr-img li{
   padding: 10px 10px 10px 10px;
  
}

.topdr-img li:hover a{
  color: #fff;
}
.topdr-img li:hover a i{
  color: #fff;
}


.topdr-img li a{
  font-size: 16px; 
  display: flex;
  color: #57575e;
  align-items: baseline;
}


.topdr-img li a i{
  margin-right: 8px;
  color: #ebad1e;
}


.topdr-img li:last-child{
  padding: 10px 10px 10px 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.cxt_tys{
  margin-top: 15px;
  padding: 0px;
  box-shadow: none;
}
.topdr-img li:hover{
  background-color: #0083ca;
  color: #fff;
}
.topdr-img li :hover i{
  color: #fff;
}.topdr-img li :hover {
  color: #fff !important;
}



.country-code-select {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.topdr-img li:after {
  content: '';
  display: block;
  position: absolute;
  top: -7px;
  right: 7px;
  width: 10px;
  height: 10px;
  border-right: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.header-search{
  padding-right: 0px;
}

.css-1yxmbwk{
  position: absolute !important;
}


.about_secs h2{
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.box_mains{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contains_box {
  background: #2a89ba;
  padding:35px 25px;
  border-radius: 15px;
}
.type_tris {
  color: #fff;
  font-size: 16px;
}
.user-heads{
  color: #fff;
  font-size: 18px;
  margin: 0px 15px 25px 0px;
}

@media screen and (max-width:767px) {
  .desk_tops{
    display: none;
  }
}

.country-dialog {
  width: 40%;
  margin: 150px auto !important;
}

.wraped-fo {
  margin-top: 37px;
  text-align: center;
  position: relative;
  top: 15px;
}
.wraped-fo {
  margin-top: 37px;
  text-align: center;
  position: relative;
  top: 15px;
}

.level1 {
  position: relative;

}
.menu-level-1 {
  position: absolute;
}

.container-web-sedo{
  margin-top: 10px;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
  max-height: 192px !important;
}

.flgx{
 min-width: 240px;

}
.bold-label {
  font-weight:600;
}

#rfs-btn {
  padding: 8px 10px !important;
  margin-top: 0px;
  position: relative;
  top: 2px;
}

.cxt-toggle {
	display : none;
}
div .txt-bts {
	position: relative;
	cursor: pointer;
	display :block;
	width: 50px;
	height: 30px;
	background: #cccccc;
	border-radius: 50px;
	transition: 300ms linear;
}

div .txt-bts:before {
	position: absolute;
	top: 50%;
	left: 2px;
	transform: translateY(-50%);
	content: "";
	display: block;
	width: 25px;
	height: 25px;
	background: #ffffff;
	border-radius: 50%;
	transition: left 300ms linear;
}

div .cxt-toggle:checked + .txt-bts {
	background: #337ab7;
}

div .cxt-toggle:checked + .txt-bts:before {
	left: 23px;
}
.mxt-pri{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  border-radius: 12px;

  margin-top: 15px;
}

.txt-mxt{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bts-center{
  display: flex;
  margin: auto;
  justify-content: center;
}

.side-use-btn {
  color: #fff;
  border: 2px solid #ebad1e !important;
  background-color: #ebad1e !important;
  padding: 12px;
}

/* Add this CSS to your stylesheet or a separate CSS file */

.loading-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of other content */
}

.loading-spinner {
  background-color: rgba(255, 255, 255, 0.8); /* Background color for the spinner container */
  border-radius: 8px; /* Rounded corners for the container */
  padding: 20px; /* Adjust padding as needed */
}

/* Customize the CircularProgress spinner if necessary */
.MuiCircularProgress-root {
  color: #007bff; /* Change the color to your desired color */
}
.center-ads{
  display: block;
}


.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #ffffff;
  background-color: #337ab7;
  border: 0;
  border-bottom-color: transparent;
  cursor: default;
}

.nav-tabs {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: #fff;
  border-radius: 3px;
}
.nav-tabs i{
  font-size: 18px;
  margin-right: 10px;
  font-weight: 600;
}
.chat-show-box{
  display: flex;
  align-items: center;
}

.swiper-padding{
  background-color: #EFEFEF;
}

.header-search .searchform{
  width: 100% !important;
}
.tabs-left{
  margin-bottom: 30px;
}
.referal-img img {
  width: 150px;
}
@media screen and (max-width:767px) {
  .dx-buton {
    position: absolute;

    /* top: 65px; */
    right: 20px;
    font-size: 12px;
    padding: 10px !important;
    /* left: 170px; */
    width: 100px;
    right:15% !important;
}
.header-center{
  padding-top: 0px;
  padding-bottom: 0px;
}
.tabs-left{
  margin-bottom: 30px;
}
.tabs-left{
  margin-bottom: 30px;
}
}
@media screen and (max-width:375px) {
  .dx-buton {
    position: absolute;

    /* top: 65px; */
    right: 20px;
    /* left: 240px; */
}
}
@media screen and (max-width:350px) {
  .dx-buton {
    position: absolute;

    /* top: 65px; */
    right: 20px;
    /* left: 180px; */
}
.img-reponsive{
  width: 55%;
  margin-top: 10px;
}
.chat-message{
  padding: 0px;
}
}

.no-chat-message img {
  width: 140px;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  position: absolute !important;
  right: 60px !important;
  top:-9px !important;
}

@media screen and (max-width:600px) {
  .app__container .css-clum0e-MuiContainer-root{
    width: 100% !important;
  }
  .css-16hqnlx h1 {
    font-size: 16px;
    font-weight: 600;
}
.css-ixk02t h1 {
  font-size: 16px;
  font-weight: 600;
}

.css-pcqfzb button {
  padding: 0px;
  font-size: 11px;
}

.css-pcqfzb h3 {
  font-size: 14px;
}

.flgx {
  min-width: 50%;
}
.MuiListItemIcon-root.css-cveggr-MuiListItemIcon-root img{
  width: 30px !important;
  height: 30px  !important;
}
  
.css-mhc70k-MuiGrid-root>.MuiGrid-item {
  padding-left: 0px !important;
}

.react-tel-input .form-control{
  width: 100% !important;
}

.css-v1sm0{
  padding: 15px !important;
  margin-top: 15px !important;
}
.css-phaq6q{
  padding: 10px !important;
  margin-top: 15px !important;
}
.social-btn img{
  margin-right: 0px !important;
}
.css-ahj2mt-MuiTypography-root{
  font-size: 12px !important;
}
}
.text_no a {
  cursor: pointer;
}


/* .square-box {
    width: 40px; 
    height: 40px; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white; 
    border: 1px solid white; 
} */
.text_end{
  display: block;
  text-align: end;
  cursor: pointer;
}
.text_end:hover{
  text-decoration: underline;
  cursor: pointer;
}

.range_btn{
  background-color: #ebad1e !important;
  color: #fff !important;
  
}
.range_btn:hover {
  background: #eb3b23 !important;
}
.slider-color {
 
  color: #ebad1e !important;

}

.ch_anjani .anjani{
  background-color: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.box_fg i{
  margin-top: 4px;
}
