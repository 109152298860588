.categoryList {
    border: none;
    background: none;
  }

  /* Styles for the custom select */
.mui-select {
  appearance: none;
  background-color: #ffffff;
  /* border-radius: 4px; */
  padding: 10px;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  border: none;
  outline: none;
}

@media screen and (max-width:767px) {
  .mui-select{
    font-size: 13px !important;
  }
  .header-search input{
    padding-left: 10px;
  }
}


.ReactFlagsSelect-module_label__27pw9 {
  font-size: 1em;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* text-wrap: wrap; */
  overflow: hidden;
  white-space: nowrap !important;
  width: 180px !important;
}
