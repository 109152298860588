.down-arrow-2nd {
    font-size: 15px;
    cursor: pointer;
    color: #99a3b0;
    position: absolute;
    top: 12px;
    /* right: 0px; */
    margin-left: 10px;
}

.my-tab .nav-tabs>li {
    float: none;
    margin-bottom: -1px;
}

.tab-pane h3 {
    font-size: 25px;
    font-weight: bold;
}

.text-white {
    color: #fff;
}

.my-usier-pic {
    height: 100px;
    width: 100px;
    border: 2px solid #2a89ba;
    padding: 20px 25px;
    border-radius: 100%;
    margin: auto;

}

.edit-icon {
    position: relative;


}

.edit-icon a {
    position: absolute;
    top: -20px;
    right: -25px;
    font-size: 20px;
    padding: 5px 9px;
    border-radius: 100%;
    background-color: #2a89ba;
    color: rgb(255, 255, 255);

}

.verify-btn-p {
    position: relative;

}

.verify-btn {
    position: absolute;
    right: 0;
    top: -50px;

}

.contact_form_submit-2 {
    background-color: #337ab7;
    border: none;
    color: #fff;
    padding: 6px 16px;

    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 2px;
}

.fafa-3 {
    background-color: #ebad1e;
    padding: 20px 26px;
    border-radius: 100%;
}

.fafa-4 i {
    color: #fff
}

.fafa-4 {
    background-color: #ebad1e;
    padding: 20px 23px;
    border-radius: 100%;
}


.fafa-2 {
    background-color: #337ab6;
    padding: 8px;
    border-radius: 100%;
}

.fafa-1 {
    background-color: #c3def5;
    padding: 8px;
    border-radius: 100%;
}

.fafa-3 i {
    color: #fff;
}

.box-my {
    width: 100%;
    height: 55px;

    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 20px;

}

.box-myboder {
    border-right: 1px solid #fff;

}

.my-points {
    display: flex;
    align-items: center;
}

.box-dot {
    cursor: pointer;
    border-width: 3px;
    border-color: #337ab7;
    border-style: dashed;
}

.box-dot h3:hover{
color:#807777;
}

.box-myboder-2 {
    justify-content: start;

}


.my-points-2 {
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
}


/* tap? */
.tab-pane:not(:first-child) {
position: inherit;

}

.tab-pane:not(:first-child) {
width: 100%;
height: 100%;
top: 0;
left: 0;
}
.tab-content > .tab-pane {
display: none;
visibility: hidden;
}
.tab-content .tab-pane.active {
display: block;
visibility: inherit;

}
.right-txt{
text-align: right;
}
.min-box-user{
display: flex;
align-items: center;
}
.min-box-user .msg-img{
margin-left: 0px;
}
