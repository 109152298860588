.down-arrow-2nd {
    font-size: 15px;
    cursor: pointer;
    color: #99a3b0;
    position: absolute;
    top: 12px;

    margin-left: 10px;
}

.my-tab .nav-tabs>li {
    float: none;
    margin-bottom: -1px;
}

.tab-pane h3 {
    font-size: 25px;
    font-weight: bold;
}

.text-white {
    color: #fff;
}

.my-usier-pic {
    height: 100px;
    width: 100px;
    border: 2px solid #2a89ba;
    padding: 20px 25px;
    border-radius: 100%;
    margin: auto;

}

.edit-icon {
    position: relative;
}

.edit-icon a {
    position: absolute;
    top: -20px;
    right: -25px;
    font-size: 20px;
    padding: 5px 9px;
    border-radius: 100%;
    background-color: #2a89ba;
    color: rgb(255, 255, 255);
}

.verify-btn-p {
    position: relative;
}

.verify-btn {
    position: absolute;
    right: 0;
    top: -50px;
}

.contact_form_submit-2 {
    background-color: #337ab7;
    border: none;
    color: #fff;
    padding: 6px 16px;

    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 2px;
}

.fafa-3 {
    background-color: #ebad1e;
    padding: 20px 26px;
    border-radius: 100%;
}

.fafa-4 i {
    color: #fff
}

.fafa-4 {
    background-color: #ebad1e;
    padding: 20px 23px;
    border-radius: 100%;
}


.fafa-2 {
    background-color: #337ab6;
    padding: 8px;
    border-radius: 100%;
}

.fafa-1 {
    background-color: #c3def5;
    padding: 8px;
    border-radius: 100%;
}

.fafa-3 i {
    color: #fff;
}

.box-my {
    width: 100%;
    height: 100px;

    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;


}

.box-myboder {
    border-right: 1px solid #fff;

}

.my-points {
    display: flex;
    align-items: center;
}

.box-dot {
    border-width: 3px;
    border-color: #337ab7;
    border-style: dashed;
}

.box-myboder-2 {
    justify-content: start;

}


.my-points-2 {
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
}



/* tap? */
.tab-pane:not(:first-child) {
position: inherit;

}

.tab-pane:not(:first-child) {
width: 100%;
height: 100%;
top: 0;
left: 0;
}
.tab-content > .tab-pane {
display: none;
visibility: hidden;
}
.tab-content .tab-pane.active {
display: block;
visibility: inherit;

}
.right-txt{
text-align: right;
}
.min-box-user{
display: flex;
align-items: center;
}
.min-box-user .msg-img{
margin-left: 0px;
}
.heart-cx-icon {
    display: flex;
    z-index: 9;
  }
  .featured-s{
    z-index: 9; 
  }

.heart-cx-icon button, .heart-cx-icon div {
    background: none !important;
    width: 30px;
    height: 30px;
    margin-right: 5px;
    border-radius: 5px;
  }
  .heart-cx-icon div{
    margin-right: 0;
  }
  .heart-cx-icon button:hover  {
    color: #000;
  }


  
  .form-groups {
    display: block;
    margin-bottom: 15px;
  }
  
  .form-groups input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .form-groups label {
    position: relative;
    cursor: pointer;
  }
  
  .form-groups label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #000;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin: 5px;
  }
  
  .form-groups input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 12px;
    width: 6px;
    height: 12px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .proImg {
    position: relative;
  }
  .proImg::after{
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 180px;
    top: 0;
    background-image: url("../../public/images/SoldOUTBig.png");
    background-repeat: no-repeat;
  background-position: center;
  }

  .avatar-upload {
    position: relative;
    max-width: 205px;
    margin: 50px auto;
  }
  .avatar-upload .avatar-edit {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 10px;
  }
  .avatar-upload .avatar-edit input {
    display: none;
  }
  .avatar-upload .avatar-edit input + label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #FFFFFF;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    /* transition: all 0.2s ease-in-out; */
  }
  .avatar-upload .avatar-edit input + label:hover {
    background: #f1f1f1;
    border-color: #d6d6d6;
  }
  .avatar-upload .avatar-edit input + label:after {
    content: "\f040";
    font-family: 'FontAwesome';
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  background-color: #337ab7;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  }
  .avatar-upload .avatar-preview {
    width: 192px;
    height: 192px;
    position: relative;
    border-radius: 100%;
    border: 6px solid #F8F8F8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }
  .avatar-upload .avatar-preview > div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  